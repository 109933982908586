import Splide from '@splidejs/splide';

const newsSections = document.querySelectorAll('.js-slider-cards');

if (newsSections.length > 0) {
    const handleCardsSlider = (sliderEl, sliderInstance) => {
        if (!sliderInstance) {
            sliderInstance = new Splide(sliderEl, {
                pagination: false,
                arrows: false,
                perPage: 3,
                gap: '16px',
                trimSpace: false,
                breakpoints: {
                    992: {
                        perPage: 2,
                    },
                    769: {
                        perPage: 1,
                        padding: {
                            right: '10%',
                        },
                    },
                },
            }).mount();
        }

        return sliderInstance;
    };

    newsSections.forEach(section => {
        const sliderEl = section.querySelector('.cards__carousel');
        if (!sliderEl) return;
        let sliderInstance = null;
        window.addEventListener('resize', () => {
            sliderInstance = handleCardsSlider(sliderEl, sliderInstance);
        });
        sliderInstance = handleCardsSlider(sliderEl, sliderInstance);
    });
}
